import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from "../../widgets/Page"

/* Components */
import { Input, TextArea, Button } from "../../components"

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'

/* REST API */
import { send } from "../../api/Support"

/* Swal */
import Swal from 'sweetalert2'



/* Page Contact */
class Contacts extends Component {

    constructor() {
        super()

        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            waiting: false,
        }
    }

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }



    send = () => {

        const { name, phone, message, email } = this.state

        let text = `${email ? `Email: ${email} ` : ''}${message ? `Сообщение: ${message}` : ''}`

        if (!name) {
            this._name.error("Введите имя")
            return
        }

        if (!phone) {
            this._phone.error("Введите телефон")
            return
        }

        const filtered = phone.replace(/\D/g, '')

        if (filtered.length < 11) {
            this._phone.error("Введите телефон")
            return
        }

        this.setState({ waiting: true })

        send({ name, phone, message: text }).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    html: `<p class="notification">Заявка успешно отправлена!</p>`,
                    icon: 'success',
                    color: 'white',
                    background: 'black',
                    showConfirmButton: false,
                    position: 'top-right',
                    timer: 1000,
                    backdrop: false,
                    width: 400,
                    customClass: { icon: 'notification-icon', htmlContainer: 'notification-html', popup: 'notification-popup' }
                })
                this.setState({ name: "", phone: "", email: "", message: "", waiting: false })
            }
            else {
                Swal.fire({
                    html: `<p class="notification">Что-то пошло не-так!<br /> Попробуйте позже.</p>`,
                    icon: 'error',
                    color: 'white',
                    background: 'black',
                    showConfirmButton: false,
                    position: 'top-right',
                    timer: 1000,
                    backdrop: false,
                    width: 400,
                    customClass: { icon: 'notification-icon', htmlContainer: 'notification-html', popup: 'notification-popup' }
                })
                this.setState({ waiting: false })
            }
        }).catch(() => {
            Swal.fire({
                html: `<p class="notification">Что-то пошло не-так!<br /> Попробуйте позже.</p>`,
                icon: 'error',
                color: 'white',
                background: 'black',
                showConfirmButton: false,
                position: 'top-right',
                timer: 1000,
                backdrop: false,
                width: 400,
                customClass: { icon: 'notification-icon', htmlContainer: 'notification-html', popup: 'notification-popup' }
            })
            this.setState({ waiting: false })
        })
    }


    render = () => {

        const { name, email, phone, message, waiting } = this.state

        const meta = {
            title: `Контакты | General Machinery Group`,
            description: 'General Machinery Group — компания, занимающаяся реализацией спецтехники для различных отраслей. Предлагает широкий выбор оборудования, обеспечивая качество и надежность.',
            canonical: `${env.link}/contacts`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'General Machinery Group, спецтехники, реализацией спецтехники, оборудования, качество'
                }
            }
        }

        return (
            <DocumentMeta {...meta}>
                <div className="page">

                    <Header back />

                    <div className="page-content">

                        <div className="contact-wrapper">

                            <h1>Контакты</h1>

                            <div className="contact-container">

                                <div className="contact-info">

                                    <div className="contact-info-inner">

                                        <div className="contact-element">
                                            <img src="/images/phone-sale.png" alt="Phone" />
                                            <div className="contact-box">
                                                <div className="contact-text">Отдел продаж: </div>
                                                <a href="tel: +7 (771) 191-01-12" className="contact-value">+7 (771) 191-01-12</a>
                                            </div>
                                        </div>

                                        <div className="contact-element">
                                            <img src="/images/phone-percentage.png" alt="Phone" />
                                            <div className="contact-box">
                                                <div className="contact-text">Руководитель отдела лизинга: </div>
                                                <a href="tel: +7 (771) 228-48-97" className="contact-value">+7 (771) 228-48-97</a>
                                            </div>
                                        </div>

                                        <div className="contact-element">
                                            <img src="/images/phone-truck.png" alt="Phone" />
                                            <div className="contact-box">
                                                <div className="contact-text">Руководитель отдела СВХ: </div>
                                                <a href="tel: +7 (771) 191-01-17" className="contact-value">+7 (771) 191-01-17</a>
                                            </div>
                                        </div>

                                        <div className="contact-element">
                                            <img src="/images/phone-up.png" alt="Phone" />
                                            <div className="contact-box">
                                                <div className="contact-text">Руководитель отдела маркетинга (коммерческих предложений) </div>
                                                <a href="tel: +7 (777) 222-22-53" className="contact-value">+7 (777) 222-22-53</a>
                                            </div>
                                        </div>

                                        <div className="contact-element">
                                            <img src="/images/location.png" alt="Location" className="smaller" />
                                            <div className="contact-box">
                                                <div className="contact-text">Адрес</div>
                                                <div className="contact-value">Алматинская область, Илийский район, Энергетический сельский округ, село Отеген батыр, улица Ілияс Жансүгіров, 73</div>
                                            </div>
                                        </div>

                                        <div className="contact-element">
                                            <img src="/images/email.png" alt="Email" className="smaller" />
                                            <div className="contact-box">
                                                <div className="contact-text">Email</div>
                                                <div className="contact-value">info@generalmachinerygroup.kz</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="contact-form">
                                    <Input ref={ref => this._name = ref} label="Ваше имя" value={name} onChange={event => this.setState({ name: event.target.value })} required />
                                    <Input ref={ref => this._phone = ref} label="Телефон" mask="+7 (999) 999-99-99" value={phone} onChange={event => this.setState({ phone: event.target.value })} required />
                                    <Input label="Email" value={email} onChange={event => this.setState({ email: event.target.value })} />
                                    <TextArea label="Сообщение" value={message} onChange={event => this.setState({ message: event.target.value })} />
                                    <Button loading={waiting} onClick={() => this.send()} height={55}>Отправить</Button>
                                </div>

                            </div>

                            <iframe title="Yamap" src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac2088e4aeb863a6774ab1548dc4f5678dfeac9c1f5356a43e902a42b85a5516e&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>

                        </div>
                    </div>

                    <Footer />

                </div>
            </DocumentMeta>
        )
    }

}

export default Contacts