import React, { Component } from "react"

/* Mask */
import InputMask from 'react-input-mask'


/* Component Input */
class Input extends Component {

    constructor() {
        super()

        this.state = {
            focused: false,
            secured: true,
            error: ""
        }
    }


    /* Show Error */
    error = error => this.setState({ error })

    /* On Focus */
    focus = () => {

        const { clear } = this.props

        if (clear !== null && clear !== undefined) {
            clear()
        }

        this.setState({ focused: true, error: "" })
    }

    /* On Blur */
    blur = () => {

        const { clear } = this.props

        if (clear !== null && clear !== undefined) {
            clear()
        }

        this.setState({ focused: false, error: "" })
    }


    render = () => {

        const { value, label, secure, disabled, type, required, mask, ...props } = this.props
        const { focused, error, secured } = this.state

        if (disabled) {
            return (
                <div className="form-input-disabled">
                    <div className="form-input-disabled-label">{label}</div>
                    {value}
                </div>
            )
        }

        return (
            <div className={`form-input ${(focused || value !== "") ? "form-input-focused" : ""}`}>

                <div onClick={() => !focused && this._input.focus()} className="form-input-label">{label} {required ? <span>*</span> : ""}</div>

                {mask
                    ? <InputMask
                        {...props}
                        value={value}
                        onFocus={() => this.focus()}
                        onBlur={() => this.blur()}
                        ref={ref => this._input = ref}
                        mask={mask}
                    />
                    : <input
                        {...props}
                        value={value}
                        onFocus={() => this.focus()}
                        onBlur={() => this.blur()}
                        ref={ref => this._input = ref}
                        type={(secure && secured) ? "password" : type ? type : "text"}
                    />
                }




                {secure &&
                    <div className="form-input-secure" onClick={() => this.setState({ secured: !secured })}>
                        {secured
                            ? <img src="/images/eye.png" alt="Eye" />
                            : <img src="/images/eye-off.png" alt="Eye Off" />
                        }
                    </div>
                }

                {error !== "" && <div className="form-input-error">{error}</div>}

            </div>
        )
    }

}

export default Input