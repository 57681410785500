import React, { Component } from "react"

/* Component */
import { Button, Picture } from '../../components'

/* React router */
import { Link } from "react-router-dom"

/* Widgets */
import { Header, Footer } from "../../widgets/Page"

/* Icons */
import { LoadingOutlined } from "@ant-design/icons"

/* Helpers */
import { withRouter } from '../../helpers'

/* REST API */
import { get } from '../../api/Product'

/* Constants */
import { env } from '../../constants'

/* Swal */
import Swal from 'sweetalert2'

/* Copy */
import { CopyToClipboard } from 'react-copy-to-clipboard'

/* Share */
import { WhatsappShareButton, WhatsappIcon, TelegramIcon, TelegramShareButton } from 'react-share'

/* Redux */
import { connect } from "react-redux"

/* Document */
import DocumentMeta from 'react-document-meta'



/* Page Product */
class Product extends Component {

    constructor() {
        super()

        this.state = {
            status: 'loading',
            image: null,
            data: null
        }
    }

    componentDidMount = () => {
        this.load()
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }

    load = () => {

        const { params } = this.props
        const slug = params.slug ? params.slug : ''

        get({ slug }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, image: response.data.product.image, status: 'ok' })
            }
            else {
                this.setState({ data: null, status: 'error' })
            }
        }).catch(() => {
            this.setState({ data: null, status: 'network' })
        })
    }

    /* Copy */
    copy = () => {
        Swal.fire({
            html: `<p class="notification">Ссылка успешна скопирована</p>`,
            color: 'white',
            background: 'black',
            showConfirmButton: false,
            position: 'bottom-left',
            timer: 1000,
            backdrop: false,
            width: 300
        })
    }

    request = () => {

        /* Fields */
        const { setMessage, setRequest } = this.props
        const { data } = this.state

        const url = `Заявка на продукт: ${data.product.name} Ссылка: ${env.link}/product/${data.product.slug}`

        setMessage(url)
        setRequest(true)
    }

    /* Draw Share buttons */
    _share = () => {

        /* Fields */
        const { data } = this.state
        const url = `${env.link}/product/${data.product.slug}`

        return (
            <div className="product-view-social-networks">

                {/* Link */}
                <CopyToClipboard text={url}>
                    <div onClick={() => this.copy()} className="product-view-social-copy">
                        <img src="/images/link.png" alt="Link" />
                    </div>
                </CopyToClipboard>

                {/* Whatsapp */}
                <WhatsappShareButton url={url} className="product-view-social-item">
                    <WhatsappIcon size={30} round />
                </WhatsappShareButton>

                {/* Telegram */}
                <TelegramShareButton url={url} className="product-view-social-item">
                    <TelegramIcon size={30} round />
                </TelegramShareButton>

            </div>
        )
    }

    /* Draw Content */
    _content = () => {

        const { status, data, image } = this.state

        if (status !== "ok") {
            return (
                <div className="product-page-loading">
                    <LoadingOutlined />
                </div>
            )
        }

        const meta = {
            title: `${data.product.name} | General Machinery Group`,
            description: data.product.metaDescription,
            canonical: `${env.link}/product/${data.product.slug}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: data.product.metaKeywords
                }
            }
        }

        return (
            <DocumentMeta {...meta}>
                <div className="product-container">

                    <h1>{data.product.name}</h1>
                    <div className="bread-crump">
                        <Link to="/">Главная</Link>
                        {data.product.type === "truck" ? <Link to="/catalog">Каталог</Link> : <Link to="/parts">Запчасти</Link>}
                        {data.category ? <Link to={`/${data.product.type === "truck" ? "catalog" : "parts"}/category/${data.category.slug}`}>{data.category.name}</Link> : null}
                        <span>{data.product.name}</span>
                    </div>

                    <div className="product-content">

                        <div className="product-image">

                            <div className="product-main-image">
                                <Picture type="product" uri={`${env.mediapoint}/products/${image}`} defaultOpacity={0.4} />
                            </div>

                            <div className="product-images">

                                <div onClick={() => this.setState({ image: data.product.image })} className={`product-image-item ${data.product.image === image ? 'active' : 'default'}`}>
                                    <Picture type="product" uri={`${env.mediapoint}/products/${data.product.image}`} defaultOpacity={0.4} />
                                </div>

                                {data.images && Array.isArray(data.images) && data.images.length > 0 && data.images.map((item, index) =>
                                    <div onClick={() => this.setState({ image: item.image })} className={`product-image-item ${item.image === image ? 'active' : 'default'}`} key={`${index}`}>
                                        <Picture type="product" uri={`${env.mediapoint}/products/${item.image}`} defaultOpacity={0.4} />
                                    </div>
                                )}

                            </div>

                        </div>

                        <div className="product-information">

                            <div className="product-information-name">{data.product.name}</div>

                            {this._share()}

                            <div className="product-information-item">
                                <div className="product-information-text">Бренд: </div>
                                <div className="product-information-value">{data.brand.name}</div>
                            </div>

                            <div className="product-information-item">
                                <div className="product-information-text">Артикул: </div>
                                <div className="product-information-value">{data.product.articul}</div>
                            </div>

                            {data.characteristics && Array.isArray(data.characteristics) && data.characteristics.length > 0 && data.characteristics.map((item, index) =>
                                <div className="product-information-item" key={`${index}`}>
                                    <div className="product-information-text">{item.name}: </div>
                                    <div className="product-information-value">{item.value} {item.unit}</div>
                                </div>
                            )}

                            <Button onClick={() => this.request()} bottom={20} top={20}>ОСТАВИТЬ ЗАЯВКУ</Button>

                            {data.product.description &&
                                <div className="product-information-item description">
                                    <div className="product-information-text">Описание:</div>
                                    <div className="product-information-value">{data.product.description}</div>
                                </div>
                            }

                        </div>

                    </div>
                </div>
            </DocumentMeta>
        )
    }

    render = () => {

        return (
            <div className="page">

                <Header back />

                <div className="page-content">
                    <div className="product-page-wrapper">
                        {this._content()}
                    </div>
                </div>

                <Footer />

            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        request: state.request,
        message: state.message
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRequest: data => dispatch({ type: 'SET_REQUEST', payload: data }),
        setMessage: data => dispatch({ type: 'SET_MESSAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Product))