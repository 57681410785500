import React, { Component } from "react"

/* React router */
import { Link } from "react-router-dom"

/* Widgets */
import { Header, Footer } from '../../widgets/Page'

/* Components */
import { Button } from "../../components"

/* Redux */
import { connect } from "react-redux"


/* Page More */
class MobileMore extends Component {

    render = () => {

        const { setRequest } = this.props

        return (
            <div className="page">
                <Header />

                <div className="page-content">
                    <Link to="/services" className="mobile-more-item">
                        Сервисы
                        <img src="/images/right-arrow.png" alt="Right" />
                    </Link>
                    <Link to="/about" className="mobile-more-item">
                        О компании
                        <img src="/images/right-arrow.png" alt="Right" />
                    </Link>
                    <Link to="/contacts" className="mobile-more-item">
                        Контакты
                        <img src="/images/right-arrow.png" alt="Right" />
                    </Link>

                    <div onClick={() => setRequest(true)} className="mobile-more-bottom">
                        <Button>ОСТАВИТЬ ЗАЯВКУ</Button>
                    </div>

                </div>

                <Footer />
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        request: state.request,
        message: state.message
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRequest: data => dispatch({ type: 'SET_REQUEST', payload: data }),
        setMessage: data => dispatch({ type: 'SET_MESSAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMore)