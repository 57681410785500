import React, { Component } from "react"


/* Widget Advantages */
class Advantages extends Component {

    render = () => {
        return (
            <div className="home-advantages">
                <div className="home-advatages-wrapper">
                    <h3>Почему мы?</h3>
                    <div className="home-advatages-container">

                        <div className="home-advatages-item">
                            <div className="home-advatages-number">5 лет</div>
                            <div className="home-advatages-text">на рынке</div>
                        </div>

                        <div className="home-advatages-item">
                            <div className="home-advatages-number">20 000</div>
                            <div className="home-advatages-text">Более 20 000 запасных частей на складах в наличии</div>
                        </div>

                        <div className="home-advatages-item">
                            <div className="home-advatages-number">5 000</div>
                            <div className="home-advatages-text">Реализовано более 5 000 единиц техники</div>
                        </div>

                        <div className="home-advatages-item">
                            <div className="home-advatages-number">2020</div>
                            <div className="home-advatages-text">Мы стали официальным представителем <b>Sinotruk Group</b></div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default Advantages