/* PRICE CONVERTOR */
export const convertor = price => {

    /* Check the price for the void */
    if (price === null || price === undefined)
        return ""

    const total = parseFloat(price)

    let final = Number.isInteger(total) ? total.toFixed(0) : total.toFixed(2)

    return final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "\xa0₸"
}