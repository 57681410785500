import { configureStore } from '@reduxjs/toolkit'

/* Reducers */
import request from './request'
import message from './message'

const store = configureStore({
    reducer: { request, message }
})

export { store }