const DEFAULT = false

const data = (state = DEFAULT, action) => {

    let store = state

    if (action.type === 'SET_REQUEST') {
        store = action.payload
    }

    return store
}

export default data