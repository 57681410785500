import React, { Component } from "react"


/* Component Picture */
class Picture extends Component {

    constructor() {
        super()

        this.state = {
            isLoaded: false
        }
    }

    render = () => {

        const { uri, type, defaultOpacity } = this.props
        const { isLoaded } = this.state

        const imgStyle = { opacity: isLoaded ? 1 : 0 }
        const defaultStyle = { opacity: isLoaded ? 0 : defaultOpacity !== null || defaultOpacity !== undefined ? defaultOpacity : 1 }

        let defaultImage = "category.png"
        if (type === "product") defaultImage = "product.png"

        if (!uri) {
            return <img className="default" src={`/empty/${defaultImage}`} alt="General Machinery Group" />
        }

        return (
            <div className="picture">
                <img style={defaultStyle} className="default" src={`/empty/${defaultImage}`} alt="General Machinery Group" />
                <img style={imgStyle} src={uri} onLoad={() => this.setState({ isLoaded: true })} alt="General Machinery Group" />
            </div>
        )
    }

}

export default Picture