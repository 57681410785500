import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

/* CSS */
import './design/css/app.css'
import './design/css/page.css'
import './design/css/header.css'
import './design/css/footer.css'
import './design/css/input.css'
import './design/css/button.css'

import './design/css/slider.css'
import './design/css/home.css'

import './design/css/contact.css'
import './design/css/leasing.css'
import './design/css/catalog.css'
import './design/css/pagination.css'
import './design/css/product.css'
import './design/css/request.css'
import './design/css/services.css'
import './design/css/mobile.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

reportWebVitals()
