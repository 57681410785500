import React, { Component } from "react"

/* Component TextArea */
class TextArea extends Component {

    constructor() {
        super()

        this.state = {
            focused: false,
            error: ""
        }
    }

    /* On Focus */
    focus = () => {

        const { clear } = this.props

        if (clear !== null && clear !== undefined) {
            clear()
        }

        this.setState({ focused: true, error: "" })
    }

    /* On Blur */
    blur = () => {

        const { clear } = this.props

        if (clear !== null && clear !== undefined) {
            clear()
        }

        this.setState({ focused: false, error: "" })
    }


    render = () => {

        const { value, label, required, ...props } = this.props
        const { focused } = this.state

        return (
            <div className={`form-textarea ${(focused || value !== "") ? "form-textarea-focused" : ""}`}>

                <div onClick={() => !focused && this._textarea.focus()} className="form-textarea-label">{label} {required ? <span>*</span> : ""}</div>

                <textarea
                    {...props}
                    value={value}
                    onFocus={() => this.focus()}
                    onBlur={() => this.blur()}
                    ref={ref => this._textarea = ref}
                />

            </div>
        )
    }

}

export default TextArea