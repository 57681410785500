import React, { Component } from "react"

/* Link */
import { Link } from 'react-router-dom'


/* Widget Parts */
class Parts extends Component {

    render = () => {
        return (
            <div className="home-parts">
                <div className="home-parts-wrapper">
                    <h3>Запчасти для спецтехники</h3>
                    <div className="home-parts-container">

                        <Link to="/parts/category/zapchasti" className="home-parts-item">
                            <div className="home-parts-item-inner">
                                <img src="/images/spare-parts.png" alt="Settings" />
                                <div className="home-parts-item-text">Запчасти</div>
                            </div>
                        </Link>

                        <Link to="/parts/category/tormoznaya-sistema" className="home-parts-item">
                            <div className="home-parts-item-inner">
                                <img src="/images/disc-brake.png" alt="Settings" />
                                <div className="home-parts-item-text">Тормозная система</div>
                            </div>
                        </Link>

                        <Link to="/parts/category/masla" className="home-parts-item">
                            <div className="home-parts-item-inner">
                                <img src="/images/engine-oil.png" alt="Settings" />
                                <div className="home-parts-item-text">Масла</div>
                            </div>
                        </Link>

                        <Link to="/parts/category/shiny" className="home-parts-item">
                            <div className="home-parts-item-inner">
                                <img src="/images/car.png" alt="Settings" />
                                <div className="home-parts-item-text">Шины</div>
                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        )
    }

}

export default Parts