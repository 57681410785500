import axios from "axios"
import { env } from '../constants'

/* GET */
export const get = data => {
    const uri = `${env.startpoint}product/list`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}

/* PRODUCTS */
export const products = data => {
    const uri = `${env.startpoint}product/products`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}