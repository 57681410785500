import React, { Component } from "react"

/* Components */
import { Picture } from '../../components'

/* React router */
import { Link } from "react-router-dom"

/* REST API */
import { category } from '../../api/Category'

/* Constants */
import { env } from "../../constants"



/* Widget Categories */
class Categories extends Component {

    constructor() {
        super()

        this.state = {
            data: []
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Data */
    load = () => {
        category(8).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data })
            }
            else {
                this.setState({ data: [] })
            }
        }).catch(() => {
            this.setState({ data: [] })
        })
    }

    render = () => {

        const { data } = this.state

        return (
            <div className="home-category">
                <div className="home-category-inner">
                    {data.map((category, index) =>
                        <Link to={`/catalog/category/${category.slug}`} key={`${index}`} className="home-category-item">
                            <Picture uri={`${env.mediapoint}/categories/${category.image}`} defaultOpacity={0} />
                            <div className="home-category-name">{category.name}</div>
                        </Link>
                    )}
                </div>
            </div>
        )
    }

}

export default Categories