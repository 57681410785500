import React, { Component } from "react"

/* React router */
import { Link } from "react-router-dom"

/* Components */
import { Logo } from "../../components"

/* REST API */
import { category } from '../../api/Category'



/* Widget Footer */
class Footer extends Component {

    constructor() {
        super()

        this.state = {
            data: []
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Data */
    load = () => {
        category(6).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data })
            }
            else {
                this.setState({ data: [] })
            }
        }).catch(() => {
            this.setState({ data: [] })
        })
    }

    render = () => {

        const { data } = this.state
        const { main } = this.props

        return (
            <div className={`footer ${main ? 'footer-main' : 'footer-default'}`}>
                <div className="footer-wrapper">

                    <div className="footer-item">

                        <Link to="/" className="footer-logo">
                            <Logo color="white" dcolor="white" />
                            <div className="footer-logo-text">General Machinery Group</div>
                        </Link>

                        <div className="footer-description">
                            <b>General Machinery Group</b> — компания, занимающаяся реализацией спецтехники для различных отраслей. Предлагает широкий выбор оборудования, обеспечивая качество и надежность.
                        </div>

                        <div className="footer-text">ТОО "General Machinery Group"</div>

                        <div className="footer-social">

                            <a href="https://www.instagram.com/general_machinery_group/" target="_black">
                                <img src="/images/social/instagram.png" alt="Instagram" />
                            </a>

                            <a href="https://youtube.com" target="_black">
                                <img src="/images/social/youtube.png" alt="Youtube" />
                            </a>

                            <a href="https://go.2gis.com/kqqlv" target="_black" className="map" >
                                <img src="/images/social/2gis.jpg" alt="2GiS" />
                            </a>

                        </div>

                    </div>

                    <div className="footer-links">
                        <div className="footer-title">Категории</div>
                        {data.map((category, index) => {

                            if (index < 6) {
                                return <Link to={`/catalog/category/${category.slug}`} key={`${index}`}>{category.name}</Link>
                            }

                            return <div key={`${index}`} />
                        })}
                    </div>

                    <div className="footer-links">
                        <div className="footer-title">Ссылки</div>
                        <Link to="/catalog">Спец техника</Link>
                        <Link to="/parts">Запчасти</Link>
                        <Link to="/leasing">Лизинг</Link>
                        <Link to="/services">Сервисы</Link>
                        <Link to="/about">О компании</Link>
                        <Link to="/contacts">Контакты</Link>
                    </div>

                    <div className="footer-item">

                        <div className="footer-title">Контакты</div>

                        <div className="footer-contact">
                            <img src="/images/phone-call.png" alt="Phone" />
                            <div className="footer-contact-box">
                                <div className="footer-contact-text">Телефон</div>
                                <a href="tel: +7 (771) 191-01-12" className="footer-contact-value">+7 (771) 191-01-12</a>
                            </div>
                        </div>

                        <div className="footer-contact">
                            <img src="/images/location.png" alt="Location" />
                            <div className="footer-contact-box">
                                <div className="footer-contact-text">Адрес</div>
                                <div className="footer-contact-value">Алматинская область, Илийский район, село Отеген батыр, улица Ілияс Жансүгіров, 73</div>
                            </div>
                        </div>

                        <div className="footer-contact">
                            <img src="/images/email.png" alt="Email" />
                            <div className="footer-contact-box">
                                <div className="footer-contact-text">Email</div>
                                <a href="mailto: info@generalmachinerygroup.kz" className="footer-contact-value">info@generalmachinerygroup.kz</a>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="footer-bottom">
                    © TOO "General Machinery Group" {new Date().getFullYear()} Все права защищены.
                </div>

            </div>
        )
    }

}

export default Footer