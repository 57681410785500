import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from '../../widgets/Page'
import { Parts } from '../../widgets/Home'

/* Page Parts */
class MobileParts extends Component {

    render = () => {
        return (
            <div className="page">
                <Header />
                <Parts />
                <Footer />
            </div>
        )
    }
}

export default MobileParts