import React, { Component } from "react"

/* Router */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

/* Pages */
import { Page404, Home, About, Contacts, Leasing, Catalog, Product, Services, Search } from './pages/Home'
import { MobileParts, MobileCatalog, MobileMore } from './pages/Mobile'

/* Redux */
import { Provider } from 'react-redux'
import { store } from './store'

/* Widgets */
import Request from "./widgets/Page/Request"

/* Components */
import { MobileTab } from './components'

/* Modal */
import Modal from "react-modal"

/* Default Settings */
Modal.setAppElement("#root")


/* Entry Point */
class App extends Component {

    render = () => {
        return (
            <Provider store={store}>
                <Router>
                    <Routes>

                        <Route path="*" element={<Page404 />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/search" element={<Search />} />
                        <Route path="/leasing" element={<Leasing />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/catalog" element={<Catalog type="truck" />} />
                        <Route path="/catalog/category/:slug" element={<Catalog type="truck" />} />
                        <Route path="/parts" element={<Catalog type="parts" />} />
                        <Route path="/parts/category/:slug" element={<Catalog type="parts" />} />

                        <Route path="/product/:slug" element={<Product type="parts" />} />

                        <Route path="/mobile/parts" element={<MobileParts />} />
                        <Route path="/mobile/catalog" element={<MobileCatalog />} />
                        <Route path="/more" element={<MobileMore />} />

                    </Routes>

                    <Request />

                    <MobileTab />

                </Router>
            </Provider>
        )
    }

}

export default App