const DEFAULT = ""

const data = (state = DEFAULT, action) => {

    let store = state

    if (action.type === 'SET_MESSAGE') {
        store = action.payload
    }

    return store
}

export default data