import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from "../../widgets/Page"

/* Components */
import { Button } from "../../components"

/* Constants */
import { utils } from "../../helpers"

/* Redux */
import { connect } from "react-redux"

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'


const MIN = 28000000
const MAX = 100000000
const MIN_TERM = 6
const MAX_TERM = 37
const MIN_PERCENTAGE = 9
const MAX_PERCENTAGE = 28


/* Leasing */
class Leasing extends Component {

    constructor() {
        super()

        this.state = {
            total: String(MIN).replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            init: String(MIN * 0.2).replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            term: String(MIN_TERM),
            percentage: String(MIN_PERCENTAGE),

            totalValid: false,
            initValid: false,
            termValid: false,
            percentageValid: false,
        }
    }

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }


    /* TOTAL CHANGE ACTION */
    totalChange = value => {

        if (value === null || value === undefined || value === "") {
            this.setState({ total: "0", init: "0", totalValid: true, initValid: true })
            return
        }

        const str = value.replaceAll(' ', '')
        const digit = str.replace(/\D/, '')
        const total = parseFloat(digit)
        const init = (total * 0.2).toFixed(0)

        if (total >= MAX + 1) {
            return
        }

        let valid = false
        if (total < MIN || total > MAX) {
            valid = true
        }

        const final = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        const initFinal = init.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

        this.setState({ total: final, init: initFinal, totalValid: valid, initValid: valid })
    }

    /* INIT CHANGE ACTION */
    initChange = value => {

        const { total } = this.state

        if (value === null || value === undefined || value === "") {
            this.setState({ init: "0", initValid: true })
            return
        }

        const str = value.replaceAll(' ', '')
        const digit = str.replace(/\D/, '')
        const init = parseFloat(digit)

        const strTotal = total.replaceAll(' ', '')
        const digitTotal = strTotal.replace(/\D/, '')
        const finalTotal = parseFloat(digitTotal)

        if (init > finalTotal) {
            return
        }

        let valid = false
        if (init < finalTotal * 0.2 || init > finalTotal) {
            valid = true
        }

        const final = init.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

        this.setState({ init: final, initValid: valid })
    }

    /* TERM CHANGE ACTION */
    termChange = value => {

        if (value === null || value === undefined || value === "") {
            this.setState({ term: "0", termValid: true })
            return
        }

        const digit = value.replace(/\D/, '')
        const term = parseInt(digit)

        if (term > MAX_TERM) {
            return
        }

        let valid = false
        if (term < MIN_TERM || term > MAX_TERM) {
            valid = true
        }

        this.setState({ term: String(term), termValid: valid })
    }

    /* PERCENTAGE CHANGE ACTION */
    percentageChange = value => {

        if (value === null || value === undefined || value === "") {
            this.setState({ percentage: "0", percentageValid: true })
            return
        }

        const digit = value.replace(/\D/, '')
        const percentage = parseInt(digit)

        if (percentage > MAX_PERCENTAGE) {
            return
        }

        let valid = false
        if (percentage < MIN_PERCENTAGE || percentage > MAX_PERCENTAGE) {
            valid = true
        }

        this.setState({ percentage: String(percentage), percentageValid: valid })
    }


    /* Calculate action */
    calculate = () => {

        const { total, init, term, percentage, totalValid, initValid, termValid, percentageValid } = this.state

        if (initValid || termValid || totalValid || percentageValid) {
            return { payment: 0, percentage: 0, credit: 0 }
        }

        const strTotal = total.replaceAll(' ', '')
        const digitTotal = strTotal.replace(/\D/, '')
        const finalTotal = parseFloat(digitTotal)

        const strInit = init.replaceAll(' ', '')
        const digitInit = strInit.replace(/\D/, '')
        const finalInit = parseFloat(digitInit)

        const strTerm = term.replaceAll(' ', '')
        const digiterm = strTerm.replace(/\D/, '')
        const finalerm = parseFloat(digiterm)

        const strPercentage = percentage.replaceAll(' ', '')
        const digitPercentage = strPercentage.replace(/\D/, '')
        const finalPercentage = parseFloat(digitPercentage)

        const credit = finalTotal - finalInit

        const payment = (credit * finalPercentage / 100 + credit) / finalerm

        return { payment: payment.toFixed(2), credit }
    }


    request = () => {

        /* Fields */
        const { setMessage, setRequest } = this.props
        const { total, init, term, percentage } = this.state
        const result = this.calculate()

        const text = `Заявка на ЛИЗИНГ: Сумма ${total} ₸, Первоначальный взнос ${init} ₸, Срок ${term} мес. Ежемесячный платеж: ${utils.convertor(result.payment)} Сумма займа: ${utils.convertor(result.credit)} Процентная ставка: ${percentage}%`

        setMessage(text)
        setRequest(true)
    }


    render = () => {

        const { total, totalValid, init, initValid, term, termValid, percentage, percentageValid } = this.state
        const result = this.calculate()

        const meta = {
            title: `Лизинг | General Machinery Group`,
            description: 'General Machinery Group — компания, занимающаяся реализацией спецтехники для различных отраслей. Предлагает широкий выбор оборудования, обеспечивая качество и надежность.',
            canonical: `${env.link}/leasing`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'General Machinery Group, спецтехники, реализацией спецтехники, оборудования, качество'
                }
            }
        }

        return (
            <DocumentMeta {...meta}>
                <div className="page">

                    <Header />

                    <div className="page-content">
                        <div className="leasing-wrapper">

                            <h1>Лизинг от General Machinery Group</h1>
                            <div className="leasing-text">Процентная ставка от 9% до 28%</div>

                            <div className="leasing-container">
                                <div className="leasing-calculator">

                                    <div className="leasing-form-row">
                                        <div className="leasing-form-box">
                                            <div className="leasing-form-title">Стоимость спецтехники</div>
                                            <input inputMode="numeric" type="text" value={total} onChange={event => this.totalChange(event.target.value)} className={`${!totalValid ? 'default' : 'danger'}`} />
                                            <div className="leasing-form-symbol">₸</div>
                                            <div className="leasing-form-text">от {utils.convertor(MIN)}</div>
                                        </div>
                                        <div className="leasing-form-box">
                                            <div className="leasing-form-title">Первоначальный взнос</div>
                                            <input inputMode="numeric" type="text" value={init} onChange={event => this.initChange(event.target.value)} className={`${!initValid ? 'default' : 'danger'}`} />
                                            <div className="leasing-form-symbol">₸</div>
                                            <div className="leasing-form-text">от 20% ({utils.convertor(MIN * 0.2)})</div>
                                        </div>
                                    </div>

                                    <div className="leasing-form-term">
                                        <div className="leasing-form-title">Срок лизинга</div>
                                        <input inputMode="numeric" type="text" value={term} onChange={event => this.termChange(event.target.value)} className={`${!termValid ? 'default' : 'danger'}`} />
                                        <div className="leasing-form-symbol">месяцев</div>
                                        <input type="range" value={parseInt(term)} onChange={event => this.termChange(event.target.value)} min={MIN_TERM} max={MAX_TERM} />
                                        <div className="leasing-form-term-row">
                                            <div className="leasing-form-term-item">от {MIN_TERM}</div>
                                            <div className="leasing-form-term-item">до {MAX_TERM}</div>
                                        </div>
                                    </div>

                                    <div className="leasing-form-term">
                                        <div className="leasing-form-title">Процентная ставка</div>
                                        <input inputMode="numeric" type="text" value={percentage} onChange={event => this.percentageChange(event.target.value)} className={`${!percentageValid ? 'default' : 'danger'}`} />
                                        <div className="leasing-form-symbol">%</div>
                                        <input type="range" value={parseInt(percentage)} onChange={event => this.percentageChange(event.target.value)} min={MIN_PERCENTAGE} max={MAX_PERCENTAGE} />
                                        <div className="leasing-form-term-row">
                                            <div className="leasing-form-term-item">от {MIN_PERCENTAGE}</div>
                                            <div className="leasing-form-term-item">до {MAX_PERCENTAGE}</div>
                                        </div>
                                    </div>

                                    <div className="leasing-support">
                                        <div className="leasing-support-text">При поддержке:</div>
                                        <div className="leasing-support-list">

                                            <div className="leasing-support-item">
                                                <img src="/supports/1.png" alt="Support" />
                                            </div>

                                            <div className="leasing-support-item">
                                                <img src="/supports/2.png" alt="Support" />
                                            </div>

                                            <div className="leasing-support-item">
                                                <img src="/supports/3.png" alt="Support" />
                                            </div>

                                            <div className="leasing-support-item">
                                                <img src="/supports/4.png" alt="Support" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="leasing-result">

                                    <div className="leasing-result-text">Ежемесячный платеж</div>
                                    <div className={`leasing-result-payment ${result.payment === 0 ? 'hidden' : 'default'}`}>{utils.convertor(result.payment)}</div>

                                    <div className="leasing-result-text">Сумма займа</div>
                                    <div className="leasing-result-value">{utils.convertor(result.credit)}</div>

                                    <div className="leasing-result-text">Процентная ставка</div>
                                    <div className="leasing-result-value">{percentage}%</div>

                                    <div className="leasing-result-bottom">
                                        <Button disabled={totalValid || initValid || termValid || percentageValid} onClick={() => this.request()} top={30}>Отправить заявку</Button>
                                        <div className="leasing-form-term-offer-text">Расчёт не является офертой</div>
                                    </div>
                                </div>

                            </div>


                            <div className="leasing-info">

                                <div className="leasing-item">
                                    <img src="/images/check.png" alt="Check" />
                                    <div className="leasing-item-text">Доступность оборудования</div>
                                </div>

                                <div className="leasing-item">
                                    <img src="/images/calendar.png" alt="Check" />
                                    <div className="leasing-item-text">Гибкость платежей</div>
                                </div>

                                <div className="leasing-item">
                                    <img src="/images/secure-payment.png" alt="Check" />
                                    <div className="leasing-item-text">Отсутствие переплаты</div>
                                </div>

                                <div className="leasing-item">
                                    <img src="/images/clock.png" alt="Check" />
                                    <div className="leasing-item-text">Ускоренное одобрение</div>
                                </div>

                                <div className="leasing-item">
                                    <img src="/images/support.png" alt="Check" />
                                    <div className="leasing-item-text">Поддержка клиентов</div>
                                </div>

                                <div className="leasing-item">
                                    <img src="/images/hand-shake.png" alt="Check" />
                                    <div className="leasing-item-text">Стабильное сотрудничество</div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <Footer />

                </div>
            </DocumentMeta>
        )
    }

}




const mapStateToProps = state => {
    return {
        request: state.request,
        message: state.message
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRequest: data => dispatch({ type: 'SET_REQUEST', payload: data }),
        setMessage: data => dispatch({ type: 'SET_MESSAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leasing)