import React, { Component } from "react"


/* Widget Brands */
class Brands extends Component {

    render = () => {
        return (
            <div className="home-brands">
                <div className="home-brands-wrapper">
                    <h3>Бренды</h3>
                    <div className="home-brands-container">
                        <img src="/brands/1.png" alt="SHACMAN" />
                        <img src="/brands/2.png" alt="DONGFENG" />
                        <img src="/brands/4.png" alt="CHINA-LUTONG" />
                        <img src="/brands/5.png" alt="SHANTUI" />
                        <img src="/brands/6.png" alt="LIUGONG" />
                        <img src="/brands/7.png" alt="SANY" />
                        <img src="/brands/9.png" alt="HOWO" />
                        <img src="/brands/3.png" alt="ZOOMLION" />
                        <img src="/brands/10.png" alt="XCMG" />
                    </div>
                </div>
            </div>
        )
    }

}

export default Brands