import React, { Component } from "react"


/* Widget Partners */
class Partners extends Component {

    render = () => {
        return (
            <div className="home-partners">
                <div className="home-partners-wrapper">
                    <h3>Нам доверяют</h3>
                    <div className="home-partners-container">
                        <img src="/partners/1.png" alt="Partner" />
                        <img src="/partners/3.jpg" alt="Partner" />
                        <img src="/partners/5.png" alt="Partner" />
                        <img src="/partners/6.png" alt="Partner" />
                        <img src="/partners/7.png" alt="Partner" />
                        <img src="/partners/8.png" alt="Partner" />
                        <img src="/partners/9.jpg" alt="Partner" />
                        <img src="/partners/2.png" alt="Partner" />
                    </div>
                </div>
            </div>
        )
    }

}

export default Partners