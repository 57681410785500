import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'



/* Component Button */
class Button extends Component {

    render = () => {

        const {
            size,
            width, height, color, textColor,
            top, bottom, left, right, disabled,
            children, loading, onClick,
        } = this.props

        let fontSize = 14
        let buttonWidth = '100%'
        let buttonHeight = 50
        let bgColor = '#9A0B0D'
        let boxShadow = `default`
        let txColor = 'white'
        let marginTop = 0
        let marginBottom = 0
        let marginLeft = 0
        let marginRight = 0

        if (size) fontSize = size
        if (width) buttonWidth = width
        if (height) buttonHeight = height
        if (color) bgColor = color
        if (color) boxShadow = `none`
        if (textColor) txColor = textColor
        if (top) marginTop = top
        if (bottom) marginBottom = bottom
        if (left) marginLeft = left
        if (right) marginRight = right

        const style = {
            fontSize,
            width: buttonWidth,
            height: buttonHeight,
            backgroundColor: bgColor,
            color: txColor,
            boxShadow,
            marginTop,
            marginBottom,
            marginLeft,
            marginRight
        }

        if (disabled) {
            return (
                <div className="button disabled" style={style}>
                    {children}
                </div>
            )
        }

        if (loading) {
            return (
                <div className="button disabled" style={style}>
                    <LoadingOutlined />
                </div>
            )
        }

        return (
            <div onClick={onClick} className="button" style={style}>
                {children}
            </div>
        )
    }

}

export default Button