import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from "@ant-design/icons"

/* REST API */
import { products } from '../../api/Home'

/* Widgets */
import { Product } from '../Product'

/* React Router */
import { Link } from "react-router-dom"


/* Widget Products */
class Products extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: 'loading'
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Data */
    load = () => {
        products().then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data, status: 'ok' })
            }
            else {
                this.setState({ data: [], status: 'error' })
            }
        }).catch(() => {
            this.setState({ data: [], status: 'network' })
        })
    }

    render = () => {

        const { data, status } = this.state

        if (status !== 'ok') {
            return (
                <div className="home-catalog-loading">
                    <LoadingOutlined />
                </div>
            )
        }

        return (
            <div className="home-catalog">
                <div className="home-catalog-wrapper">
                    <h3>Каталог спец техники</h3>
                    <div className="home-catalog-products">
                        {data.map((product, index) => <Product data={product} key={`${index}`} />)}
                    </div>

                    <div className="home-catalog-bottom">
                        <Link to="/catalog" className="home-catalog-button">Посмотреть все</Link>
                    </div>

                </div>
            </div>
        )
    }
}

export default Products
