import React, { Component } from "react"

/* Widgets */
import { Header, Footer } from "../../widgets/Page"

/* Document */
import DocumentMeta from 'react-document-meta'

/* Constants */
import { env } from '../../constants'


/* Page Search */
class Search extends Component {

    componentDidMount = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }

    render = () => {

        const meta = {
            title: `Поиск | General Machinery Group`,
            description: 'General Machinery Group — компания, занимающаяся реализацией спецтехники для различных отраслей. Предлагает широкий выбор оборудования, обеспечивая качество и надежность.',
            canonical: `${env.link}/search`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'General Machinery Group, спецтехники, реализацией спецтехники, оборудования, качество'
                }
            }
        }

        return (
            <DocumentMeta {...meta}>
                <div className="page">

                    <Header back />

                    <div className="page-content">
                        <h1>По вашему запросу ничего не найдено!</h1>
                    </div>

                    <Footer />

                </div>
            </DocumentMeta >
        )
    }

}

export default Search