import React, { Component } from "react"

/* Link */
import { Link } from 'react-router-dom'

/* Components */
import { Button, Picture } from "../../components"

/* Constants */
import { env } from '../../constants'


/* Widget Product */
class Product extends Component {

    render = () => {

        const { data } = this.props

        if (!data) {
            return <div />
        }

        return (
            <Link to={`/product/${data.slug}`} className="home-catalog-product">
                <Picture type="product" uri={`${env.mediapoint}/products/${data.image}`} alt={data.name} defaultOpacity={0.2} />
                <div className="home-catalog-product-name">{data.name}</div>
                <Button color="#222">Посмотреть</Button>
            </Link>
        )
    }

}

export default Product