import React, { Component } from "react"

/* Components */
import { Input, Button } from "../../components"

/* Modal */
import Modal from "react-modal"

/* Redux */
import { connect } from "react-redux"

/* REST API */
import { send } from "../../api/Support"

/* Swal */
import Swal from 'sweetalert2'



/* Widget Request */
class Request extends Component {

    constructor() {
        super()

        this.state = {
            name: "",
            phone: "",
            waiting: false,
        }
    }

    close = () => {

        const { setRequest, setMessage } = this.props

        setRequest(false)
        setMessage("")
        this.setState({ name: "", phone: "", waiting: false })
    }

    send = () => {

        const { name, phone } = this.state
        const { message } = this.props

        if (!name) {
            this._name.error("Введите имя")
            return
        }

        if (!phone) {
            this._phone.error("Введите телефон")
            return
        }

        const filtered = phone.replace(/\D/g, '')

        if (filtered.length < 11) {
            this._phone.error("Введите телефон")
            return
        }

        this.setState({ waiting: true })

        send({ name, phone, message }).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    html: `<p class="notification">Заявка успешно отправлена!</p>`,
                    icon: 'success',
                    color: 'white',
                    background: 'black',
                    showConfirmButton: false,
                    position: 'top-right',
                    timer: 1000,
                    backdrop: false,
                    width: 400,
                    customClass: { icon: 'notification-icon', htmlContainer: 'notification-html', popup: 'notification-popup' }
                })
                this.close()
            }
            else {
                Swal.fire({
                    html: `<p class="notification">Что-то пошло не-так!<br /> Попробуйте позже.</p>`,
                    icon: 'error',
                    color: 'white',
                    background: 'black',
                    showConfirmButton: false,
                    position: 'top-right',
                    timer: 1000,
                    backdrop: false,
                    width: 400,
                    customClass: { icon: 'notification-icon', htmlContainer: 'notification-html', popup: 'notification-popup' }
                })
                this.setState({ waiting: false })
            }
        }).catch(() => {
            Swal.fire({
                html: `<p class="notification">Что-то пошло не-так!<br /> Попробуйте позже.</p>`,
                icon: 'error',
                color: 'white',
                background: 'black',
                showConfirmButton: false,
                position: 'top-right',
                timer: 1000,
                backdrop: false,
                width: 400,
                customClass: { icon: 'notification-icon', htmlContainer: 'notification-html', popup: 'notification-popup' }
            })
            this.setState({ waiting: false })
        })
    }

    render = () => {

        const { request } = this.props
        const { name, phone, waiting } = this.state

        return (
            <Modal
                isOpen={request}
                onRequestClose={() => this.close()}
                className="request-modal"
                overlayClassName="request-overlay"
                closeTimeoutMS={200}
            >

                <div className="request-modal-header">
                    <h2>Оставить заявку</h2>
                    <div onClick={() => this.close()} className="request-modal-close">
                        <img src="/images/x.png" alt="Close" />
                    </div>
                </div>

                <div className="request-form">
                    <Input ref={ref => this._name = ref} label="Ваше имя" value={name} onChange={event => this.setState({ name: event.target.value })} required />
                    <Input ref={ref => this._phone = ref} label="Телефон" mask="+7 (999) 999-99-99" value={phone} onChange={event => this.setState({ phone: event.target.value })} required />
                    <Button onClick={() => this.send()} loading={waiting} height={55} top={20}>Отправить</Button>
                </div>

            </Modal>
        )
    }

}

const mapStateToProps = state => {
    return {
        request: state.request,
        message: state.message
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRequest: data => dispatch({ type: 'SET_REQUEST', payload: data }),
        setMessage: data => dispatch({ type: 'SET_MESSAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Request)